import React from 'react';
import { Link } from 'react-router-dom';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import investmentbankingimg from '../images/investment banking.svg';
import projectfinancingimg from '../images//projectfinancing.svg';
import privatecapitalsolutionimg from '../images/ privatecapitalsolutions.svg';
import managementconsultancyimg from '../images/managementconsultancy.svg';
import privateequityimg from '../images/privateequity.svg';
import enterprisevaluationimg from '../images/enterprisevaluation.svg';

function ServicesSection() {
  return (
    <>
    <section className="section service" id="services" aria-label="service">
      <div className="container">
        <p className="section-subtitle text-center">-What We Offer-</p>
        <h2 className="h2 section-title text-center">Overview</h2>
        <p className="section-text text-center">
        Fincept Pro is a leading company that offers customized financial services solutions for successful entrepreneurs and pioneers in  economy.  </p>
        <ul className="grid-list">
          <li>
            <div className="service-card has-after">
              <figure className="card-icon">
                <img src={investmentbankingimg} width="130" height="130" loading="lazy" alt="UI/UX Creative Design" className="img" />
              </figure>
              <div className="card-content">
                <h3 className="h3 card-title">Investment Banking</h3>
                <p className="card-text">
                Fincept Pro stands as one of the leading specialised banking service provider, offering a comprehensive suite of debt and equity solutions, mergers and acquisitions (M&A), and alternative investments.</p>
                <Link to='/services/investmentbanking' className="btn-link">
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
              <figure className="card-icon">
                <img src={privatecapitalsolutionimg} width="200" height="200" loading="lazy" alt="App Development" className="img" />
              </figure>
              <div className="card-content">
                <h3 className="h3 card-title">Private Capital Solutions</h3>
                <p className="card-text">
                Fincept Pro positions itself as a leading provider of private captial solutions, catering to the diverse financial needs of businesses across industries. 
                </p>
                <Link to='/services/privatecapital' className="btn-link">
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                  </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
              <figure className="card-icon">
                <img src={managementconsultancyimg} width="140" height="140" loading="lazy" alt="Professional Content Writer" className="img" />
              </figure>
              <div className="card-content">
                <h3 className="h3 card-title">Management Consultancy</h3>
                <p className="card-text">
                Fincept Pro is your partner in unlocking your economic potential, providing solutions that are tailored and backed by extensive industry expertise. 
                </p>
                <Link to='/services/managementconsultancy' className="btn-link">
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
              <figure className="card-icon">
              <img src={projectfinancingimg} width="200" height="200" loading="lazy" alt="App Development" className="img" />
              </figure>
              <div className="card-content">
                <h3 className="h3 card-title">Project Financing</h3>
                <p className="card-text">
                Fincept Pro leads unique project finance solutions, meeting the special needs of large projects and asset acquisition.</p>
                <Link to='/services/projectfinancing' className="btn-link">
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
              <figure className="card-icon">
                <img src={privateequityimg} width="180" height="180" loading="lazy" alt="UI/UX Creative Design" className="img" />
              </figure>
              <div className="card-content">
                <h3 className="h3 card-title">Private Equity</h3>
                <p className="card-text">
                Fincept Pro is a leading partner in private equity, offering specialized services designed to improve your financial journey. </p>
                <Link to='/services/privatequity' className="btn-link">
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                  </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
              <figure className="card-icon">
                <img src={enterprisevaluationimg} width="180" height="180" loading="lazy" alt="UI/UX Creative Design" className="img" />
              </figure>
              <div className="card-content">
                <h3 className="h3 card-title">Enterprise Valuation</h3>
                <p className="card-text">
                Fincept Pro empowers strategic transactions with unparalleled enterprise valuation expertise, ensuring clarity and confidence for every step of your journey. </p>
                <Link to='/services/enterprisevaluation' className="btn-link">
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                  </Link>
              </div>
            </div>
          </li>
          {/* Add more <li> items for additional services */}
        </ul>
      </div>
    </section>
    </>
  );
}

export default ServicesSection;
