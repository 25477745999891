import React from 'react'
import { Link } from 'react-router-dom';
import healthimg from "../images/Health.jpeg";
import businessimg from "../images/Bussiness.jpeg";
import industryimg from "../images/Industry.jpeg";
import energyimg from "../images/energy.jpeg";
import telecomimg from "../images/telecom.jpeg";
import consumeimg from "../images/consumer.jpeg";
import { BsArrowUpCircleFill } from "react-icons/bs";

const SectorPageCards = () => {
  return (
    <>
    <section className="section service" id="services" aria-label="service">
      
      <div className="container" style={{textAlign:'center',marginTop:'-60px'}}>
      <BsArrowUpCircleFill style={{ fontSize:'3rem', marginBottom:'30px', color:'#75B8B9'}}/>

        <ul className="grid-list gridlist">
        
          <li>
            <div className="service-card has-after">
            <Link to="/sectors/healthcare"> <figure className="card-icon">
                <img src={healthimg} width="100%" height="100%" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
              </figure>
              <div className="card-content">
               <h3 className="h3 card-title"style={{textAlign:'center',paddingTop:'10px'}}>Healthcare & Pharmaceuticals</h3>
            
                
              </div></Link>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
            <Link to="/sectors/business"> <figure className="card-icon">
            <img src={businessimg} width="100%" height="280" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
              </figure>
              <div className="card-content">
               <h3 className="h3 card-title"style={{textAlign:'center',paddingTop:'10px'}}>Business Services</h3>
            
                 
              </div></Link>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
            <Link to="/sectors/industrial"> <figure className="card-icon">
                <img src={industryimg} width="100%" height="100%" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
              </figure>
              <div className="card-content">
               <h3 className="h3 card-title " style={{textAlign:'center',paddingTop:'10px'}}>Industrial</h3>
            
                 
              </div></Link>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
            <Link to="/sectors/telecom"> <figure className="card-icon">
            <img src={telecomimg} width="100%" height="100%" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
          </figure>
              <div className="card-content">
               <h3 className="h3 card-title"style={{textAlign:'center',paddingTop:'10px'}}>Telecom, Media & Technology</h3>
            
                 
              </div></Link>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
            <Link to="/sectors/consumer"> <figure className="card-icon">
            <img src={consumeimg} width="100%" height="100%" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
          </figure>
              <div className="card-content">
               <h3 className="h3 card-title" style={{textAlign:'center',paddingTop:'10px'}}>Consumer & Retail</h3>
            
                 
              </div></Link>
            </div>
          </li>
          <li>
            <div className="service-card has-after">
            <Link to="/sectors/energy"> <figure className="card-icon">
               <img src={energyimg} width="100%" height="100%" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
              </figure>
              <div className="card-content">
               <h3 className="h3 card-title"style={{textAlign:'center',paddingTop:'10px'}}>Energy</h3>
            
                 
              </div></Link>
            </div>
          </li>

         
          {/* Add more <li> items for additional services */}
        </ul>
      </div>
    </section>
    </>
  )
}

export default SectorPageCards