import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import healthimg from '../../images/healthcaresector.jpg';
import SectorsCards from '../../components/SectorCards';
import { Helmet } from 'react-helmet';
import HealthCareSectorContent from '../../components/HealthCareSectorContent';

const Healthcare = () => {
  return (
  <>
  <Helmet>

  <title>Healthcare and Pharmaceuticals Sector | Fincept Pro Financial Services</title>
  <meta name="description" content="Discover specialized financial services tailored for the global healthcare and pharmaceuticals industry by Fincept Pro."/>
  <meta name="keywords" content="healthcare financial services, pharmaceuticals finance solutions, healthcare investment banking, pharmaceutical sector financial consulting"/>

  </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Healthcare and pharmaceutical Services</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={healthimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
      </LazyLoad>

  </section>
  <HealthCareSectorContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <SectorsCards />
    <Footer/>
    </>
 
  
  )
}

export default Healthcare 