import React from 'react'
import aboutbanner from '../images/about-banner.png';
import { IoMedalOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const HeroAbout = () => {
  return (
   <>
   <section className="section about" id="about"  aria-label="about">
      <div className="container">
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
        <figure className="about-banner">
          <img src={aboutbanner}width="802" height="654" loading="lazy" alt="about banner" className="w-100" />
        </figure>
        </LazyLoad>
        <div className="about-content">
          <h2 className="h2-sm section-title">Strategic Financial Insights, Driving Global Investment Success</h2>
          <p className="section-text ">
          Fincept Pro offers comprehensive investment banking services across various industries, specializing in facilitating successful M&A transactions and fund raising needs.

</p>
          <ul className="about-list">
            <li className="has-before">
            End-to-end guidance with thorough financial analysis for fundraising, M&A, and restructuring
            </li>
            <li className="has-before">
            Tailored approaches based on company needs, investor expectations, and sector dynamics
            </li>
            <li className="has-before">
            Leveraging virtual data rooms, APIs, and analytics tools for seamless execution.
            </li>
            <li className="has-before">
            Deep understanding across sectors, maximizing value for specific needs.
            </li>
          </ul>
          <div className="btn-group">
            <Link to="/about" className="btn btn-primary">Know More</Link>
            <button className="flex-btn">
              <div className="btn-icon medalicon">
              <IoMedalOutline  />
              </div>
              <span className="span">10+ Years Experience</span>
            </button>
          </div>
        </div>
      </div>
    </section>
   
   </>
  )
}

export default HeroAbout