import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Landing from './pages /Landing';
import About from './pages /About';
import ContactUs from './pages /ContactUs';
import Blogs from './pages /Blogs';
import Sectors from './pages /Sectors';
import Healthcare from './pages /sector sub-pages/Heathcare';
import Energy from './pages /sector sub-pages/Energy';
import Consumer from './pages /sector sub-pages/Consumer';
import Telecom from './pages /sector sub-pages/Telecom';
import Business from './pages /sector sub-pages/Business';
import Industrial from './pages /sector sub-pages/Industrial';
import Services from './pages /Services';
import EnterpriseValuation from './pages /services sub-pages/EnterpriseValuation';
import InvestmentBanking from './pages /services sub-pages/InvestmentBanking';
import ManagementConsultancy from './pages /services sub-pages/ManagementConsultancy';
import PrivateCapital from './pages /services sub-pages/PrivateCapital';
import PrivateEquity from './pages /services sub-pages/PrivateEquity';
import ProjectFinancing from './pages /services sub-pages/ProjectFinancing';
// import { Helmet } from 'react-helmet';
import SustainableFinance from './components/blog content/SustainableFinance';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(){

  const router = createBrowserRouter([
    {
      path: "/",
      element:<> <Navbar/> <ScrollToTop /><Landing/></>
    },
    {
      path: "/about",
      element: <><Navbar/><ScrollToTop /><About/></>
    },
    // {
    //   path: "/about/",
    //   element: <><Navbar/><ScrollToTop /><About/></>
    // },
    {
      path: "/contact",
      element: <><Navbar/><ScrollToTop /><ContactUs/></>
    },
    // {
    //   path: "/contact/",
    //   element: <><Navbar/><ScrollToTop /><ContactUs/></>
    // },
    {
      path: "/blogs",
      element: <><Navbar/><ScrollToTop /><Blogs/></>
    },
    // {
    //   path: "/blogs/",
    //   element: <><Navbar/><ScrollToTop /><Blogs/></>
    // },
            {
              path: "/blogs/sustainable-finance",
              element: <><Navbar/><ScrollToTop /><SustainableFinance/></>
            },
    {
      path: "/sectors",
      element: <><Navbar/><ScrollToTop /><Sectors/></>
    },  
    // {
    //   path: "/sectors/",
    //   element: <><Navbar/><ScrollToTop /><Sectors/></>
    // }, 
    
              {
                path: "/sectors/healthcare",
                element: <><Navbar/><ScrollToTop /><Healthcare/></>
              },{
                path: "/sectors/energy",
                element: <><Navbar/><ScrollToTop /><Energy/></>
              },{
                path: "/sectors/consumer",
                element: <><Navbar/><ScrollToTop /><Consumer/></>
              },{
                path: "/sectors/telecom",
                element: <><Navbar/><ScrollToTop /><Telecom/></>
              },{
                path: "/sectors/business",
                element: <><Navbar/><ScrollToTop /><Business/></>
              },{
                path: "/sectors/industrial",
                element: <><Navbar/><ScrollToTop /><Industrial/></>
              },
    {
       path: "/services",
        element: <><Navbar/><ScrollToTop /><Services/></>
     },  
    //  {
    //   path: "/services/",
    //    element: <><Navbar/><ScrollToTop /><Services/></>
    // },  
              {
                path: "/services/enterprisevaluation",
                element: <><Navbar/><ScrollToTop /><EnterpriseValuation/></>
              },{
                path: "/services/investmentbanking",
                element: <><Navbar/><ScrollToTop /><InvestmentBanking/></>
              },{
                path: "/services/managementconsultancy",
                element: <><Navbar/><ScrollToTop /><ManagementConsultancy/></>
              },{
                path: "/services/privatecapital",
                element: <><Navbar/><ScrollToTop /><PrivateCapital/></>
              },{
                path: "/services/privatequity",
                element: <><Navbar/><ScrollToTop /><PrivateEquity/></>
              },{
                path: "/services/projectfinancing",
                element: <><Navbar/><ScrollToTop /><ProjectFinancing/></>
              },  {
                path: "*",
                element: <RedirectToHome />
              }
  ])
    return (
    <>

      {/* <Helmet>
      <title>Fincept Pro | India's leading financial and businesses services </title>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="description" content="Fincept Pro is India's leading financial services firm, providing investment banking, private capital solutions, project financing, enterprise valuation, and more."/>
      <meta name="keywords" content="asset management company, fidelity investment company, fidelity inc, fidelity investment co, asset management company near me, asset management group, capital group, capital group company, capital group companies inc, invest ment, investments bank, first investment, wealth management, financial services, investment company, wealth mgmt, investment co, wealth management manager, asset management, financial advisors, pacific investment management company, financial company, financial consulting, financial advisory services, financial investment,investment banking, wealth management services, wealth management companies, investment companies, financial institutions, banking, investment banking services, private equity, investment banking companies, private equity firms, private equity investment, private equity fund, healthcare investment banking, investment firms, investment management, top private equity firms, bank investments, alternative investments, banking and finance, private capital, private investment company, energy investment banking, capital raising,
    financial advisory services, banking services, private equity company, global investment, private equity financing, m&a investment banking, financial investments, private banking services, private equity market, alternative asset management, private investment firm, capital investment company, equity investments, capital investments, private investment fund, private equity fund raising, business services investment banking, banking business, financial advisory firm, private equity banking, capital company, pe investment, banking and financial services,
    private equity investment firm, investment houses, equity investment company, private capital advisory, private banking, private fund, private equity group, investment projects, sustainable private equity, equity firm, private equity management, invest ment, private equity capital raising, raising capital for a business, private equity alternative investments, investment banking firm, private equity fund management, investment banking operations, consumer retail investment banking, getting into investment banking, private equity debt financing, investment banking mergers and acquisitions,
    getting investment for your business, capital for businesses, restructuring investment banking, asset management private equity, recent investment banking transactions, private equity business, private equity analysis, pe firm, private equity tools, private alternative investments, financial services private equity, alternative investment solutions, private investment banking, it services investment banking, business investment companies, business capital investment, private equity retail, private equity debt fund, media investment banking, finance banking, private company investment, 
    private investment management, investment and business, consumer investment banking, private equity fund of fund, private equity investment company, global investment banking, retail alternative investments, raising investment capital, private equity capital, equity private, private equity fund services, investment banking advisory services, investment banking transactions, bank equity, private fund management company, business services private equity, private equity fund investment, global business banking, global investment services, telecom investment banking, investment banking advisory, 
    private capital funding, equity funding company, debt advisory investment banking, investment banking business, m&a and investment banking, private equity and investment banking, private equity growth, capital raising firm, raising private equity, private banking investment, basics of investment banking, technology private equity, banking and investment, fund financing private equity, about investment banking, investment in a business, private business investment, pe company, equity advisory services, finance investment banking, private capital investments, raising a private equity fund,
    us private equity, private equity analysis tools, financial banker, private capital market, investment banking capital raising, capital raising company, fund raising private equity, capital advisory services, private equity debt, investment banking private equity, private equity acquisitions, project investment company, banking financial services, growth capital private equity, financial businesses, industrial investment banking, financial services investment banking, private financial services, private capital raising, investment banking solutions, sourcing private equity, investment management private equity,
    private equity for beginners, growth equity investment, pe financing, capital fund company, consumer and retail investment banking, banks near me, online banking, project finance private equity, stocks to invest in, healthcare services investment banking, private equity to investment banking, private equity in investment banking, investment banking equity, understanding investment banking, bancorp bank, private banking offers, bank of, capital solutions investment banking, alternative investments firm, investment banking asset management, personal banking, private banking business, investment banking and financial services, 
    project finance to private equity, private banking company, Investment Banking Firm, Financial Services Provider, Mergers and Acquisitions, Private Capital Solutions, Project Finance Solutions, Private Equity Services, Enterprise Valuation Expertise, Management Consultancy Firm, Strategic Financial Planning, Capital Markets Expertise, Boutique Investment Bank, Financial Restructuring Advisors, Asset Management Solutions, Valuation and Risk Assessment, Institutional Investment Services,
    Fincept Pro, financial services, investment banking, private capital solutions, management consultancy, project financing, India, advisory services, mergers and acquisitions, corporate finance,Fincept Pro, financial services, investment banking, mergers and acquisitions, capital raising solutions, private equity partnerships, institutional investment services, corporate finance consultancy, IPO advisory services, strategic financial planning, financial restructuring advisors, asset management solutions, valuation and risk assessment,
    healthcare financial services, industrial sector financial solutions, telecom financial services, business services financial advisory, consumer retail finance solutions, energy sector financial consulting,investment banking projects, capital investment banking, growth in private equity, alternative to investment banking, equity advisory, capital advisory firms, investment banking and finance, media and telecom investment banking, healthcare it investment banking, investment banking it, retail investment banking, investment banking markets,
    all you need to know about investment banking, corporate banking to private equity, private equity listed companies, investment banking in a nutshell, all about investment banking, investment and financing, ib firm, debt investment companies, finance advisory company, industrial services investment banking, private banking investment account, investment services in banking, banking advisory, investment banking growth, investment banking analysis, investment banking offer, capital market investment banking, investment banking and financial services company,
    m&a in investment banking, investment banking how to, apa itu investment banking, valuation to investment banking, corporate banking to investment banking, investment banking advisory firm, top trading banks, financial services focused private equity, all equity firm, capital advisory company, corporate banking and investment banking, valuation investment banking, finance investment and banking, m&a ib, advisory services in banking, business development in investment banking, strategic advisory investment banking, capital a private equity, capital market in investment banking,
    debt capital raising, investment finance company, capital market and investment banking, private capital solutions, the financial services, finance and private equity, the bank, investment banking professional, investment banking management, investment maker, healthcare ib, operations of investment banking, business investment management, private equity fund finance, private banking to investment banking, large business banking, ib advisory, investment banking and investment management, restructuring in investment banking, investment banking in healthcare, valuation in investment banking,
    restructuring banking, investment banking global markets, debt investment banking, private debt company, equity and capital markets, private equity in finance, private equity and debt, equity private company, industrial services private equity, investment banking and asset management, investment banking consumer, debt capital management, banking advisory services, from asset management to private equity, banking and investment analysis, management company private equity, industrial private equity, retail banking to investment banking, corporate banking investment banking, retail and investment banking, 
    private investment services, private equity in financial management, m&a in banking, bankers, consumer & retail investment banking, capital private, banking and investment management, private equity in, capital equity markets, private equity i, equity private equity, it private equity, pe advisory, a private equity firm, a private equity, retail banking and investment banking, pe capital investments, banking services company, private equity it, pe equity, growth equity private equity, private capital services, capital private equity, debt equity funding, private equity development, growth equity financing,
    private equity asset, company acquired by private equity, banking valuation, as equity & investments, capital market in banking, firm equity, private investment capital, telecom banking, capital inv, capital for business private equity, private capital firm, finance capital markets, growth capital investments, asset management and private equity, m&a investments, private debt and private equity, capital market in investment management, private equity growth capital, banking and financial sector, equity investment firm, capital investment for a company, financial capital investment company, private equity and private debt,
    capital market firm, growth of capital, investments and acquisitions, global private equity firm, banking asset management, a private equity fund, finance for banking, technology in private equity, private capital development, 2024 investment banking, growth fund private equity, private funding company, banking and finance company, enterprise value equity investments, private equity fund company, investment private, global banking company, private equity expert, banking in financial services, debt capital financing, large private equity, us private equity firm, largest private investment companies, leveraging capital,
    new private equity fund, tech private equity firm, investment in investment management, banking service sector, business investment services, largest private equity firm, growth of private equity market, banking firm, the business of banking, the banking business, private banking solutions, private investment market, banking equity fund, gauge invest, private debt capital, it services private equity, mergers and acquisitions in banking, business banking and corporate banking, the private market, hf private equity, focus private equity, private financial market, sector banking, capital funding investment, valuing private equity, 
    closed private equity fund, enterprise private equity, banking operations professional, banking industrial, capital investment advisory services, professional services banking, equity of company, technology growth equity, growth equity firm, listed private equity fund, banking finance companies, management in banking and finance, banking business services, private equity global, healthcare equity capital markets, private banking operations, private market fund, financial solutions and investments, private equity telecom, partners capital management, mdcp private equity, investment management consultancy, our investment, banking and the economy,
    an investment firm, special banking services, private investment management firm, large investment firm, alternative investments company, alternative equity, private fund advisory, services provided by banking sector, strategic banking, investment and finance company, fund advisory, global private capital, operational banking, debt banking, jefferies investment banking, wealth management, private wealth management, asset management advisory, wealth management advisor, private wealth advisor, private wealth, best wealth management firms, private wealth management firms, top wealth management firms, private wealth asset management, best wealth management, 
    wealth management firms, asset management advisory services, wealth advisory, banking institutions, financial wealth management, top asset management firms, personal wealth management, institutional banking services, money management firms, private equity advisory firm, financial firm company, top investment management firms, financial institutions industry, best private wealth management, wealth management firms near me, wealth management investment, wealth management companies near me, best investment management firms, financial institution industry, banking and asset management, best wealth management companies, wealth management banks, valuation of financial institutions,
     best money management companies, bank institution, loan institutions, financial service provider company, leading financial company, largest wealth management firms, private asset management, private financial corporation, large financial company, asset wealth management, top private wealth management firms, global wealth management, financial advisor wealth management, all financial company, investment management advisory, private banking and asset management, asset management and advisory services, investment management firm, wealth banking, money management advisor, wealth management advisor near me, best private wealth management firms, private wealth management near me, 
     recent development in banking industry, international wealth management, wealth advisory services, top wealth management companies, the best wealth management firms, wealth management services near me, best wealth management firms near me, private wealth banking, wealth and asset management, private wealth management companies, corporate banks, best personal wealth management firms, wealth management private banking, wealth and investment management, wealth financial services, financial management company, investment management near me, wealth services, wealth management near me, private banking and wealth management, private wealth solutions, banking financial institutions, 
     investment management firms near me, large wealth management firms, private wealth management services, financial wealth management companies, personal investment management, credit institutions, top wealth management firms in the world, best wealth management services, wealth management as a service, largest private wealth management firms, wealth and asset management companies, top private wealth managers, financial wealth manager, wealth advisory firm, investment management service, financial services wealth management, financial and wealth management, the best wealth management companies, wealth management groups, private wealth investment, largest wealth managers, 
     largest investment management firms, institution bank, wealth company, advisor wealth management, best financial management firms, wealth management sites, global wealth, investment and wealth management, world top asset management firms, wealth management consultancy, wealth asset management companies, private asset management firms, wealth invest, largest wealth managers in the world, money wealth management, service wealth management, wealth management banking services, wealth management asset management, financial management firm, wealth management and asset management, jefferies bank, wealth advisor solutions, personal investment management companies, banks and financial institutions,
      big wealth management firms, global wealth and investment management, wealth management and investment services, asset management advisor, wealth management manager, wealth management investment companies, as wealth management, private wealth company, private banking advisor, management advisor, best wealth management firms in the world, wealth management llc, wealth management inc, wealth management advisory services, big wealth management companies, international wealth, largest wealth management companies, international wealth management firms, asset and wealth management companies, company wealth management, top asset management companies in the world, wealth structuring,
       top global wealth management firms, financial institution services, a financial institution, bank and financial institution, banks and corporations, corporate finance bank, financial bodies, financial institution branch, financial institutions and services, financial organisation, financial organizations, institutional bank, monetary financial institutions, investment management advisor, wealth management and investment management, advisor management, banking and wealth management, financial management bank, financial management inc, investment management asset management, managed solutions wealth management, private banking asset management, private banking investment banking, private wealth management llc,
        private wealth management solutions, wealth and asset management firms, wealth and asset management services, wealth and management, wealth management and investment, wealth management investment solutions, wealth management is, wealth management private, wealth management services offered by banks, wealth solutions management, wealth management ltd, wealth management advisory firm, asset management global wealth management, best asset manage"/>
      </Helmet> */}
  

 
      <RouterProvider router={router}/>

    </>
    
  );
  
}

const RedirectToHome = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
};
 export default App;



