import React from 'react'
import Footer from '../components/Footer'
import BlogPageCards from '../components/BlogPageCards'
import { BlogsBanner } from '../components/BlogsHeroBanner'
import { Helmet } from 'react-helmet'



const Blogs = () => {
  return (
  <> 
  <Helmet>
    <title>Blogs | Fincept Pro</title>
    <meta charset="UTF-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <meta name="description" content="Sustainable finance integrates environmental, social, and governance (ESG) considerations into company choices and investment strategies, addressing concerns like climate change, labor abuses, and business competitiveness. Learn more about the tech-driven revolution in sustainable finance at Fincept Pro."/>
    <meta name="keywords" content="Sustainable finance, ESG, Environmental finance, Social finance, Governance finance, Data-driven impact measurement, Fintech, Democratizing finance, AI, Blockchain, Sustainable investing, ESG-embedding executive-level decision making, Sustainable technology, Climate finance, Impact investing, Fincept Pro"/>
   </Helmet>
   <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Blogs</h1> 
    <BlogsBanner/>
    <BlogPageCards/>
    <Footer/>
  </>
    
  )
}

export default Blogs