import React from 'react';
import {  Link } from "react-router-dom";
import '../pagestyle.css';

import bannner1 from '../images/hero-banner1.png';
import ServicesSection from './ServicesSection';
import SectorsCards from './SectorCards';
import HeroAbout from './HeroAbout';
import LazyLoad from 'react-lazyload';

const Hero = () => {
  return (
   <>
     <section className="section hero" id="home" aria-label="hero">
        <div className="container">

          <div className="hero-content" >

            <p className="hero-subtitle has-before">Investment Banking Firm</p>

            <h1 className="h1 hero-title">
            PRECISION IN FINANCE EXCELLENCE IN GROWTH  </h1>

            <p className="hero-text">
            With a focus on strategic vision, we guide our clients toward sustainable growth, combining insight with the precision needed for financial success.</p>

            <div className="btn-group">
              <Link to='/services' className="btn btn-primary">View Our Services</Link>

          </div>

         

        </div>
        <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
        <figure className="hero-banner has-before img-holder" style={{ '--width': 650, '--height': 650 }}>
            <img src={bannner1} width="650" height="650" alt="hero banner" className="hero-img-cover"/>
          </figure>
        </LazyLoad>
        </div>
      </section>
      <ServicesSection/>
      <HeroAbout/>
      <SectorsCards/>
   </>
  )
}

export default Hero