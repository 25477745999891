import React from 'react'

const ConsumerSectorContent = () => {
  return (
    <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          The consumer and retail industry on a global scale is vast and varied. This dynamic sector constantly adapts and innovates which is seen in changing consumer preferences, technological advancements, and evolving shopping habits. From traditional brick-and-mortar stores to online marketplaces, consumer and retail companies navigate a diverse and ever-evolving landscape.<br></br><br></br>
          </p>
          <p className="section-text ">
          Here’s how the Consumer and Retails sector is expected to look like in the near future:<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Continued growth of e-commerce is expected due to mobile penetration, social commerce, and live-streaming platforms.
            </li>
            <li className="has-before">
            Artificial intelligence and big data will change retail experiences, enhancing personalized recommendations, optimizing supply chains, and automating operations.
            </li>
            <li className="has-before">
            Augmented reality and virtual reality will transform product trials and shopping experiences. This will, to some extent, vanish the difference between online and offline worlds.
            </li>
            <li className="has-before">
            Sustainability will become a crucial differentiator, with consumers increasingly favoring brands committed to ethical sourcing and contributing to government and environmentally conscious practices.
            </li>
          </ul>

          <p className="section-text">
          We Advise On :<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Get funding for e-commerce expansion and new technology.
            </li>
            <li className="has-before">
            Explore mergers and acquisitions for market consolidation, brand acquisitions, and strategic partnerships.
            </li>
            <li className="has-before">
            Conduct accurate valuations and due diligence to inform investment decisions and optimize asset allocation.
            </li>
            
          </ul>
          
        </div>
      </div>
    </section>
  </>
  )
}

export default ConsumerSectorContent