import React from 'react'


const TelecomPageContent = () => {
  return (
  <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          The Telecom, Media, and Technology sector consists of the interconnected worlds of communication, entertainment, and innovation. It drives digital transformation across industries, fuels economic growth, and shapes our daily lives. This sector constantly evolves, embracing cutting-edge technologies like AI, cloud computing, and 5G.<br></br><br></br>
          </p>
          <p className="section-text ">
          Here’s how Telecom, Media, and Technology are expected to look in the near future:<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Artificial intelligence (AI) is undoubtedly to revolutionise Telecom, Media, and Technology, impacting everything from content creation to network optimization. 
            </li>
            <li className="has-before">
            Augmented reality (AR) and virtual reality (VR) are expected to gain mainstream adoption, which will transform entertainment and communication experiences.
            </li>
            <li className="has-before">
            The IoT will connect billions of devices, which will generate massive amounts of data and create new business opportunities.
            </li>
            <li className="has-before">
            Concerns about data privacy and ethical AI will shape new legal regulatories.
            </li>
          </ul>

          <p className="section-text">
          We Advise On :<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Get funding for innovative technologies, content development, and infrastructure expansion.
            </li>
            <li className="has-before">
            Study mergers and acquisitions for market consolidation, talent acquisition, and technology integration.
            </li>
            <li className="has-before">
            Develop strategic financial plans for navigating regulatory changes, monetizing data assets, and optimizing investments.
            </li>
            
          </ul>
          
        </div>
      </div>
    </section>
  </>
  )
}

export default TelecomPageContent