import React from 'react';
import sectorsimg from '../images/HeroBanner.png';
import LazyLoad from 'react-lazyload'; 

export const HeroBanner = () => {
  return  (
    <section className="section" id="home" aria-label="hero">

       
        <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
        <img src={sectorsimg} alt="Sectors banner" className="img-cover" />
        <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
        </LazyLoad>

    </section>
  );
}
