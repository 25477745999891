import React from 'react'

const PrivateEquityContent = () => {
  return (
    <>
    <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
       <div className="container">
       
         <div className="about-content">
 
           <p className=" hero-text ">
           Fincept Pro is a leading partner in private equity, offering specialized services designed to improve your financial journey. Along with facilitating fundraising, we look to take a holistic approach by supporting research documentation throughout the process. From investor sourcing, where our expert algorithm matches your business with the best private equity (PE) firms, to pricing consultants, due diligence, finalization of term papers and post-investment support, Fincept Pro stands out beside you at every stage.  <br></br><br></br> </p>
         
           <p className=" hero-text ">
           Dedicated to industry and domain expertise, our teams of experts develop targeted content and strategies that align with specific industry contexts. <br></br><br></br> </p>
         
           <p className=" hero-text ">
           Fincept Pro leverages our networking experience and industry understanding to transform the financial journey into a strategic journey. <br></br><br></br>
           </p>
           <p className="section-text  ">
           Our private equity services include:<br></br></p>
           
           <ul className="about-list"style={{top:'4px'}}>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Investor Sourcing:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We identify the optimal Private Equity (PE) firms aligned to your niche, business model, targeted raise amount, and growth plans using bespoke matching algorithms.</span>
            <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Valuation Advisory:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We help establish reasonable seed, early, and growth stage valuations based on revenue and growth projections to set the right expectations for pre-fundraise.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Due Diligence:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Our digital data rooms' financial/legal/IP analytics reduce the effort needed during due diligence to progress to funding in a quick and organized manner.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Term Sheet Finalization:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We negotiate investor term sheets regarding stake sold, liquidation preferences, voting rights, etc., to ensure optimal flexibility for the founders.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Post-Investment Support:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We advise on investor communications, board governance, ESOP management, follow-on funding rounds, and eventual exit planning.</span>
             <br></br><br></br>
            
           </ul>
 
           
          
         </div>
       </div>
     </section>
   </>
  )
}

export default PrivateEquityContent