import React from 'react'
import Footer from '../components/Footer'
import { HeroBanner } from '../components/SectorHeroBanner'
import SectorPageCards from '../components/SectorPageCards'
import { Helmet } from 'react-helmet'


const Sectors = () => {
  return (
    <>
    <Helmet>
    <meta name="description" content="Discover our comprehensive range of services across Healthcare, Industrial, Telecom, Media & Technology, Business Services, Consumer & Retail, and Energy sectors, designed to innovate and lead in the global marketplace."/>
    <meta name="keywords" content="healthcare financial services, industrial sector financial solutions, telecom financial services, business services financial advisory, consumer retail finance solutions, energy sector financial consulting"/>
  <title>Sectors | Comprehensive Global Services in Healthcare, Industrial, Tech, and More Fincept Pro </title>
    </Helmet>
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Sectors</h1> 
    <HeroBanner/>
    <SectorPageCards/>
    <Footer/>
    </>
  )
}

export default Sectors