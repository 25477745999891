import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import enterpriseimg from '../../images/enterprise .jpg';
import Servicescards from '../../components/Servicescards';
import EnterpriseValuationContent from '../../components/page content/EnterpriseValuationContent';
import { Helmet } from 'react-helmet';

const EnterpriseValuation = () => {
  return (
  
   <>
   <Helmet>
    <title>Enterprise Valuation | Fincept Pro</title>
    <meta name="description" content="FinceptPro empowers strategic transactions with unparalleled enterprise valuation expertise, providing clarity and confidence for mergers and acquisitions, capital raising, financial reporting, and strategic planning."/>
    <meta name="keywords" content="FinceptPro, enterprise valuation, mergers and acquisitions, capital raising, financial reports, strategic plans, valuation services, price negotiations, fundraising support, accounting and compliance, legal dispute resolution, goodwill optimization"/>

   </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Enterprise Valuation</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={enterpriseimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
      </LazyLoad>

  </section>
  <EnterpriseValuationContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <Servicescards />
    <Footer/>
    </>
  
  )
}

export default EnterpriseValuation