import React from 'react';
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import telecomimg from '../../images/telecomsector.jpg';
import SectorsCards from '../../components/SectorCards';
import TelecomPageContent from '../../components/TelecomSectorcontent';
import { Helmet } from 'react-helmet';

const Telecom = () => {
  return (
    <>
    <Helmet>
    <title>Telecom, Media, and Technology Sector | Fincept Pro Financial Services</title>
    <meta name="description" content="Find specialized financial services for the telecom, media, and technology sectors provided by Fincept Pro."/>
    <meta name="keywords" content="telecom finance solutions, media sector financial services, technology industry investment banking, telecom advisory services"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Telecom, media and Technology Services</h1> 
     
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={telecomimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
      </LazyLoad>

  </section>
  <TelecomPageContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <SectorsCards />
    <Footer/>
    </>
  )
}

export default Telecom