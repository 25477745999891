import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import energyimg from '../../images/energy.jpg';
import SectorsCards from '../../components/SectorCards';
import { Helmet } from 'react-helmet';
import EnergySectorContent from '../../components/EnergySectorContent';

const Energy = () => {
  return (
    <>
    <Helmet>
    <title>Energy Sector | Fincept Pro</title>
    <meta name="description" content="Discover expert financial consulting services for the energy sector provided by Fincept Pro."/>
    <meta name="keywords" content="energy sector financial solutions, oil and gas industry finance, renewable energy investment banking, power sector financial consulting"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Energy Services</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={energyimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
      </LazyLoad>

  </section>
  <EnergySectorContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <SectorsCards />
    <Footer/>
    </>
  )
}

export default Energy