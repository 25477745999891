import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import investmentimg from '../../images/investmentbanking.jpg';
import Servicescards from '../../components/Servicescards';
import InvestmentBankingContent from '../../components/page content/InvestmentBankingcontent';
import { Helmet } from 'react-helmet';

const InvestmentBanking = () => {
  return (
    <>
    <Helmet>
   <title>Investment Banking | FinceptPro - Specialized Banking Services</title>
    <meta name="description" content="FinceptPro offers specialized banking services, including debt and equity solutions, mergers and acquisitions (M&A), and alternative investments."/>
    <meta name="keywords" content="FinceptPro, specialized banking services, debt solutions, equity solutions, mergers and acquisitions, M&A, alternative investments, capital raising, financial restructuring"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Investment
    Banking</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={investmentimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
      </LazyLoad>

  </section>
  <InvestmentBankingContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <Servicescards />
    <Footer/>
    </>
  )
}

export default InvestmentBanking