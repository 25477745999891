import React from 'react'
import Footer from '../components/Footer';
import { AboutBannerPage } from '../components/AboutBanner';
import aboutimg from '../images/aboutimg.jpeg';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
    <Helmet>
      <title>About Fincept Pro | Top Financial Guidance and advisory Services in India </title>
     <meta name="description" content="Fincept Pro is India's premier financial services provider, offering a comprehensive range of investment banking, consultancy, and management solutions tailored to your business needs."/>
  <meta name="keywords" content="Fincept Pro, financial services, investment banking, mergers and acquisitions, capital raising solutions, private equity partnerships, institutional investment services, corporate finance consultancy, IPO advisory services, strategic financial planning, financial restructuring advisors, asset management solutions, valuation and risk assessment"/>
 </Helmet>
    <AboutBannerPage/>
    <section className=" hero  " style={{background:'none', marginTop:'-120px'}}id="home" aria-label="hero">
        <div className="container gridabout">

          <div className="hero-content" >

          <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>About Us</h1> 
         <figure className="hero-banner " style={{ '--width':550, '--height': 150 }}>
            <img src={aboutimg} width="450" height="50" alt="about us " className="hero-img-cover"/>
          </figure>
          </div>
        
        <p className="hero-text" style={{textAlign:'left'}}>
      
            We are a team of skilled and experienced financial experts who cater to corporates and business owners who are looking to grow and scale. We provide financial services like - investment banking, mergers and acquisitions, capital raising solutions, private equity partnerships, and institutional investment services through our global network. Not only this, but we also provide you with directions to utilize those funds. <br></br><br></br>
            We have consultancy and financial advisory services like - corporate finance consultancy, IPO advisory services, strategic financial planning, financial restructuring advisors, asset management solutions, valuation and risk assessment, and a lot more.<br></br><br></br>
            At Fincept Pro, we believe your ambition deserves strategic partnership, not just fleeting transactions. We're a team of seasoned professionals driven by a singular mission: to empower businesses and founders like you to scale globally with tailored financial solutions and expert guidance.<br></br><br></br>
            We understand that each company has a unique financial requirement. That's why we go beyond simply raising capital/funds and become your trusted advisor. We work very closely with your team to understand your vision and craft bespoke solutions that fuel your growth journey. Our comprehensive suite of services, spanning investment banking, consultancy, and management, allows us to address your needs holistically, from initial funding to strategic expansion.<br></br><br></br>
            Our team boasts extensive experience in investment banking, corporate finance, and strategic consulting, honed through more than 11 years of working with leading companies across various sectors. We combine this expertise with a commitment to transparency, integrity, and client-centricity, ensuring a collaborative and trustworthy partnership every step of the way.<br></br><br></br>



            
        </p>
  
      
       
       
        </div>
      </section>
    <Footer/>
    </>
  )
}

export default About;