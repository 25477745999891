
import React from "react";
import {  Link } from "react-router-dom";
import '../pagestyle.css';
import logo from '../images/fincept_logo.png';
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
  return (
  <>
   <footer className="footer">

<div className="footer-top section">
  <div className="container">

    <div className="footer-brand">

    <Link to="/" className="nav__logo">
         <img className="logocss" style={{  marginLeft: '1px'}} src={logo} alt="Fincept Pro" />
    </Link>
      <p className="footer-text h4" style={{color:'#76808e'}}>
      Achieving precision in financial operations while concurrently fostering excellence in advancing growth.
      </p>

      {/* <form action="" className="newsletter-form">
        <input type="email" name="email_address" placeholder="Enter your email" required className="email-field"/>

        <button type="submit" className="form-btn">
        <FaPaperPlane />
        </button>
      </form> */}

    </div>

    <ul className="footer-list">

      <li>
        <p className="footer-list-title">Our Services</p>
      </li>

      <li>
        <Link to='/services/investmentbanking' className="footer-link">Investment Banking</Link>
      </li>
      <li>
        <Link to='/services/privatequity' className="footer-link">Private Equity</Link>
      </li>
     
      <li>
        <Link to='/services/projectfinancing' className="footer-link">Project Financing</Link>
      </li>
      <li>
        <Link to='/services/enterprisevaluation' className="footer-link">Enterprise Valuation</Link>
      </li>
      <li>
        <Link to='/services/managementconsultancy' className="footer-link">Management Consultancy</Link>
      </li>
       
      <li>
        <Link to='/services/privatecapital' className="footer-link">Private Capital Solutions</Link>
      </li>
      
    

    </ul>

    <ul className="footer-list">

      <li>
        <p className="footer-list-title">Company</p>
      </li>

      <li>
        <Link to="/about" className="footer-link">About Us</Link>
      </li>

      <li>
        <Link to='/services' className="footer-link">Services</Link>
      </li>
      <li>
        <Link to='/sectors' className="footer-link">Sectors</Link>
      </li>
      <li>
        <Link to='/blogs' className="footer-link">Blogs</Link>
      </li>

      <li>
        <Link to='/contact' className="footer-link">Contact Us</Link>
      </li>

    </ul>

    <ul className="footer-list">

      <li>
        <p className="footer-list-title">Contact Us</p>
      </li>

      <li className="footer-item">
      <FaLocationDot style={{marginTop:'5px'}} />

        <address className="contact-link address">
          Chandigarh, Punjab, 140413
        </address>
      </li>

      <li className="footer-item">
      <FaPhone style={{marginTop:'5px'}}  />
      <Link to="tel:+91 8264097191" class="contact-link">+91-8264097191</Link>
      </li>

      <li className="footer-item">
      <IoMdMail style={{marginTop:'5px'}}/>

        <Link to="mailto:info@finceptpro.com" className="contact-link">info@finceptpro.com</Link>
      </li>

      <li className="footer-item">
        <ul className="social-list">

          <li>
            <Link to='https://www.linkedin.com/company/finceptpro' target="_blank" className="social-link">
            <FaLinkedinIn style={{marginTop:'5px'}} />
            </Link>
          </li>

          <li>
            <Link to='https://twitter.com' target="_blank"className="social-link">
            <FaXTwitter  style={{marginTop:'5px'}} />
            </Link>
          </li>

        </ul>
      </li>

    </ul>

  </div>
</div>

<div className="footer-bottom">
  <div className="container">

    <p className="copyright">
      &copy; 2024 Fincept Pro | All Rights Reserved by <Link to='/' className="copyright-link">Fincept Pro</Link>
    </p>

    <ul className="footer-bottom-list">

    

    </ul>

  </div>
</div>

</footer>
  </>
  );
};

export default Footer;
