import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import '../contact.css';
import Footer from '../components/Footer';
import { FaMapLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { IoMdThumbsUp } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';

function ContactUs() {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorModalOpen, seterrorModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    message: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  // State to track whether all fields are filled
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    // Check if all fields in formData are not empty
    const isFilled = Object.values(formData).every(val => val !== '');
    setIsFormFilled(isFilled);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_j2kb5af', 'template_j96egwt', form.current, {
        publicKey: '8IZszR-caeKLPAeTn',
        ...formData // Spread the formData object
      })
      .then(
        () => {
          // console.log('SUCCESS!');
          setIsModalOpen(true);
          setFormSubmitted(true);
        },
        (error) => {
          // console.log('FAILED...', error.text);
          seterrorModalOpen(true);
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Fincept Pro</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="For wealth creation, investment banking, credit solutions and asset management solutions in India or outside India, connect via email or a call with Fincept Pro Corporate Offices." />
        <meta name="keywords" content="contact us, Fincept Pro" />
      </Helmet>
      <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>contact us</h1> 
      <div className="containers">
        <div className="big-circle"></div>
        <div className="forms">
          <div className="contact-info">
            <h3 className="title">Let's get in touch </h3>
            <p className="text">

              Partner with Fincept Pro and discover the unparalleled difference.</p>


            <div className="info">
              <div className="information">
                <FaMapLocationDot className=" icon" />
                <p>Chandigarh, Punjab, 140413</p>
              </div>
              <div className="information">
                <MdEmail className=" icon" />
                <p>info@finceptpro.com</p>
              </div>
              <div className="information">
                <IoCall className=" icon" />
                <Link to="tel:+91 8264097191" class="contact-link">+91-8264097191</Link>
              </div>
            </div>

            <h3 className="title"> Book a call now!</h3>
            <div className="social-media">
              <p>Connect with Us</p>
              <div className="social-icons">
                <Link to='https://twitter.com' target="_blank" ><FaXTwitter style={{ marginTop: '9px' }} /></Link>
                <Link to='https://www.linkedin.com/company/finceptpro' target="_blank"><FaLinkedinIn style={{ marginTop: '9px' }} /></Link>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <div className="circle one"></div>
            <div className="circle two"></div>

            {!formSubmitted && (
              <form ref={form} onSubmit={sendEmail}>
                <h3 className="title">Contact Us</h3>
                <div className="input-container">
                  <input
                    type="text"
                   
                    name="user_name"
                    placeholder="Name"
                    className="input"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Email"
                    className="input"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="tel"
                    name="user_phone"
                    placeholder="Phone"
                    className="input"
                    value={formData.user_phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-container textarea">
                  <textarea
                    name="message"
                    placeholder="Message"
                    className="input"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <input type="submit" value="Send" className="btns" disabled={!isFormFilled} />
              </form>
            )}

            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Thank you message"
              className="modal" // Apply the modal class
              overlayClassName="modal-overlay" // Optional: Add styles to the overlay
            >
              <div className="modal-content">
                <h2 className="modal-title">Thank You for Reaching Out! <IoMdThumbsUp /></h2>
                <p className="modal-message">
                  We will get back to you as soon as possible. In the meantime, feel free to explore more about our services and solutions on our website.
                </p>
                <button className="close-btn" onClick={() => setIsModalOpen(false)}>
                  <GrClose />
                </button>
              </div>
            </Modal>

            <Modal
              isOpen={errorModalOpen}
              onRequestClose={() => seterrorModalOpen(false)}
              contentLabel="Thank you message"
              className="modal" // Apply the modal class
              overlayClassName="modal-overlay" // Optional: Add styles to the overlay
            >
              <div className="modal-content">
                <h2 className="modal-title">Oops!</h2>
                <h2 className="modal-titsle">Message Sending Failed !</h2>
                <p className="modal-message">
                  Please try again later.<br></br> Feel free to contact us directly via email at  <Link to="mailto:info@finceptpro.com" className="contact-link" style={{ display: 'inline' }}>info@finceptpro.com</Link> or connect via <Link to="tel:+91 8264097191" class="contact-link" style={{ display: 'inline' }}>+91-8264097191</Link>. We apologize for any inconvenience and appreciate your patience.
                </p>
                <button className="close-btn" onClick={() => seterrorModalOpen(false)}>
                  <GrClose />
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;