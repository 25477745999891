import React from 'react'

const EnterpriseValuationContent = () => {
  return (
    <>
    <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
       <div className="container">
       
         <div className="about-content">
 
           <p className=" hero-text ">
           FinceptPro empowers strategic transactions with unparalleled enterprise valuation expertise, ensuring clarity and confidence for every step of your journey. Our expert team delivers tailored valuation services to meet your specific needs, whether you’re navigating mergers and acquisitions, seeking capital, preparing financial reports, or charting strategic plans.  <br></br><br></br> </p>
         
           <p className=" hero-text ">
           Enterprise valuation involves more than just crunching numbers. It's a strategic tool that provides valuable insights. By analyzing your company's financial health, assessing growth potential, and minimizing risks, we can assist you in making well-informed investment decisions. Additionally, our high-quality valuations help identify room for enhancement in your business performance, strategy, or governance. <br></br><br></br> </p>
         
           <p className=" hero-text " >
           Our commitment to excellence drives our valuation process. We follow industry-leading practices and methodologies, ensuring the highest standards of accuracy and reliability. Our team relies on rigorous analysis and reliable data sources to provide objective valuations. Whether determining fair market value or assessing intangible assets, we maintain transparency and integrity. <br></br><br></br>
           </p>
           <p className=" hero-text ">
           Fincept Pro offers the best enterprise valuation services. We understand that the value of your company is not  just by its number and assets but beyond that. Thus, we dive deep into the dynamics of your business to get you the best valuation.<br></br><br></br>

           </p>
           <p className="section-text  ">
           Our Enterprise valuation services include:<br></br></p>
           
           <ul className="about-list"style={{top:'4px'}}>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Price Negotiations:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We provide fair value estimates during merger, acquisition, and fundraising discussions to enable negotiations.</span>
            <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Fundraising Support:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Our future-looking valuation models establish seed, series A to pre-initial public offerings benchmarks for startups and growth-stage companies raising PE/VC capital.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Accounting and Compliance:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We conduct annual impairment testing and intangible valuations to support financial audits, tax compliance, and reporting needs.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Legal Dispute Resolution:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Our valuation reports have helped resolve legal disputes related to merger disagreements, investor exits, and breach of contracts.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Goodwill Optimization:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We also advise on goodwill valuation, purchase price allocation, and intangible assets measurement to optimize balance sheets post-merger deals.</span>
            
            
           </ul>
           <p >
           Led by CVA-certified specialists and leveraging proprietary data models, we provide a 360-degree perspective on value. <br></br><br></br>
           </p>
 
           
          
         </div>
       </div>
     </section>
   </>
  )
}

export default EnterpriseValuationContent