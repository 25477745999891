import React from 'react'
import { Link } from 'react-router-dom';
import { HiOutlineDotsVertical } from "react-icons/hi";

const SectorsCards = () => {
  return (
   <>
   <section className="section features" id="features" aria-label="features">
      <div className="container">
        <h2 className="h2 section-title text-center">Sectors</h2>
        <p className="section-text text-center">
          Our mastery and depth of experience extend across diverse sectors, disciplines, and market domains.
        </p>
        <ul className="grid-list">
          <li>
            <div className="features-card ">
              <data className="card-number" value="01"></data>
              <Link to="/sectors/healthcare"><h3 className="h3 card-title">Healthcare & Pharmaceuticals</h3></Link>
              <p className="card-text">
              <Link to="/sectors/healthcare"className="btn-link btn-link-color">The global healthcare and pharmaceuticals market . . . <HiOutlineDotsVertical style={{fontSize:'35px'}}/>
              </Link>
             
               
              </p>
            </div>
          </li>
          <li>
            <div className="features-card">
              <data className="card-number" value="02"></data>
              <Link to="/sectors/industrial"><h3 className="h3 card-title">Industrial</h3></Link> 
              <p className="card-text">
              <Link to="/sectors/industrial"className="btn-link btn-link-color">The global industrial sector consists of a wide range of industries . . . <HiOutlineDotsVertical style={{fontSize:'35px'}}/></Link>
              </p>
            </div>
          </li>
          <li>
            <div className="features-card">
              <data className="card-number" value="03"></data>
              <Link to="/sectors/telecom"><h3 className="h3 card-title">Telecom, Media & Technology</h3></Link>
              <p className="card-text">
              <Link to="/sectors/telecom"className="btn-link btn-link-color">The Telecom, Media, and Technology sector consists of the . . . <HiOutlineDotsVertical style={{fontSize:'35px'}}/></Link>
              </p>
            </div>
          </li>
          <li>
            <div className="features-card">
              <data className="card-number" value="04"></data>
              <Link to="/sectors/business"> <h3 className="h3 card-title">Business Services</h3></Link>
              <p className="card-text">
              <Link to="/sectors/business"className="btn-link btn-link-color">The business services sector has a diverse range of professional services  . . . <HiOutlineDotsVertical style={{fontSize:'35px'}}/></Link>
              </p>
            </div>
          </li>
          <li>
            <div className="features-card">
              <data className="card-number" value="05"></data>
              <Link to="/sectors/consumer"><h3 className="h3 card-title">Consumer & Retail</h3></Link>
              <p className="card-text">
              <Link to="/sectors/consumer"className="btn-link btn-link-color">The consumer and retail industry on a global scale is vast and varied. This dynamic . . . <HiOutlineDotsVertical style={{fontSize:'35px'}}/></Link>
              </p>
            </div>
          </li>
          <li>
            <div className="features-card">
              <data className="card-number" value="06"></data>
              <Link to="/sectors/energy"><h3 className="h3 card-title">Energy</h3></Link> 
              <p className="card-text">
              <Link to="/sectors/energy"className="btn-link btn-link-color">The energy sector consists of the development, production, and distribution  . . . <HiOutlineDotsVertical style={{fontSize:'35px'}}/></Link>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
   </>
  )
}

export default SectorsCards

