import React from 'react';
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import bussinessimgsec from '../../images/business.jpg';
import SectorsCards from '../../components/SectorCards';
import { Helmet } from 'react-helmet';
import BusinessSectorContent from '../../components/BusinessSectorContent';

const Business = () => {
  return (
   <>
   <Helmet>
  <title>Business Services Sector | Fincept Pro Financial Advisory</title>
    <meta name="description" content="Access expert financial advisory services tailored for the business services sector provided by Fincept Pro."/>
    <meta name="keywords" content="business services financial consulting, professional services finance solutions, corporate finance advisory, business sector investment banking"/>

   </Helmet>
  <section className="section" id="home" aria-label="hero">
      
  <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Business Services</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={bussinessimgsec} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
      </LazyLoad>

  </section>
  <BusinessSectorContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <SectorsCards />
    <Footer/>
   </>
  )
}

export default Business