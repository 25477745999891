import React from 'react'

const BusinessSectorContent = () => {
  return (
    <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          The The business services sector has a diverse range of professional services that helps the operations and growth of other businesses. From accounting and consulting to HR and IT, these services ensure smooth functioning, optimize performance and drive innovation across industries. The sector has shown consistent growth driven by increasing outsourcing trends and rising demand for specialized expertise.<br></br><br></br>
          </p>
          <p className="section-text ">
          Here’s how the Business Services sector is expected to look in the near future:<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Automation will bring drastic changes in the sector, which will impact traditional service models and create new job opportunities in data analysis and technology management. </li>
            <li className="has-before">
            Sustainability and ESG considerations will gain prominence, driving demand for eco-friendly solutions and ethical service practices. </li>
            <li className="has-before">
            Specialization and niche expertise will be key differentiators for the businesses seeking consultants and service providers tailored to their specific needs.
            </li>
            <li className="has-before">
            Concerns about data privacy and ethical AI will shape new legal regulatories.
            </li>
          </ul>

          <p className="section-text">
          We Advise On :<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Develop robust financial plans for optimizing resource allocation, managing growth, and capitalizing on emerging opportunities.
            </li>
            <li className="has-before">
            Get funding for expansion, new technology, and strategic acquisitions within the sector.
            </li>
            <li className="has-before">
            Conduct insightful valuations and risk assessments to inform mergers, acquisitions, and divestitures.
            </li>
            
          </ul>
          
        </div>
      </div>
    </section>
  </>
  )
}

export default BusinessSectorContent