import React from 'react'

const IndustrialSectorContent = () => {
  return (
    <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          The The global industrial sector consists of a wide range of industries, like manufacturing, construction, utilities, and mining. It is the backbone of the economy as it contributes significantly to GDP and employment. Although the sector is performing well, it is always transforming and innovating in technology and sustainability initiatives for better than best results.<br></br><br></br>
          </p>
          <p className="section-text ">
          Here is what the industrial sector is estimated to look like in the near future:<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Continued focus on automation and digitalization will entirely change how the work is done and will bring a huge advancement to traditional manufacturing methods.
             </li>
            <li className="has-before">
            Supply chain and localization will be prioritized to improve the efficiency and mitigate the risks.
             </li>
            <li className="has-before">
            The IoT will connect billions of devices, which will generate massive amounts of data and create new business opportunities.
            </li>
            <li className="has-before">
            Sustainability will become a key factor. Putting money into it will be looked at as an investment in clean technologies and circular economy models, which are expected to surge.
            </li>
          </ul>

          <p className="section-text">
          We Advise On :<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Get funding for infrastructure projects, green initiatives, and technology upgrades.
            </li>
            <li className="has-before">
            Develop robust financial plans for navigating market changes and optimizing investments.
            </li>
            <li className="has-before">
            Explore M&A opportunities for consolidation, diversification, and strategic partnerships.
            </li>
            
          </ul>
          
        </div>
      </div>
    </section>
  </>
  )
}

export default IndustrialSectorContent