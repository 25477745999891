import React from 'react'

const HealthCareSectorContent = () => {
  return (
    <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          The global healthcare and pharmaceuticals market is massive. It is seen that the Health and pharmaceutical sector experiences consistent growth and innovation due to many factors like ageing population, chronic disease prevalence, and technological advancements.<br></br><br></br>
          </p>
          <p className="section-text ">
          And here is what healthcare and pharmaceuticals are estimated to look like in the near future:<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Personalized medicine, gene therapy, and AI-based diagnostics are expected to revolutionize healthcare delivery.
             </li>
            <li className="has-before">
            Emerging markets will generate significant growth through expanding insurance coverage and increasing disposable income.
            </li>
            <li className="has-before">
            Increased emphasis on preventive health care and value-based health care models will reshape the industry landscape.
            </li>
            
          </ul>

          <p className="section-text">
          We Advise On :<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Raise capital for R&D, clinical trials, and market expansion.
            </li>
            <li className="has-before">
            Obtain strategic partnerships and mergers to increase market share and technology acquisition.
            </li>
            <li className="has-before">
            Develop strong financial plans to understand regulatory changes and optimize resource allocation. 
            </li>
            
          </ul>
          
        </div>
      </div>
    </section>
  </>
  )
}

export default HealthCareSectorContent