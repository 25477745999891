import React from 'react'

const EnergySectorContent = () => {
  return (
    <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          The energy sector consists of the development, production, and distribution of energy resources like oil, gas, electricity, and renewables. It fuels global economies, power industries, and also has a contribution in shaping our daily lives. Energy sector is facing the dual challenge of increasing demand and people transitioning to cleaner sources. The energy sector is, therefore, undergoing rapid transformation, which demands innovation and strategic adaptation.<br></br><br></br>
          </p>
          <p className="section-text ">
          Here’s how the Energy sector is expected to look like in the near future:<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            There will be a transition of people to clean energy, and renewable energies amongst them will surely take a central role in meeting future energy demands.
            </li>
            <li className="has-before">
            Technological advancements in battery storage, grid modernization, and carbon capture will be crucial for enabling reliable and sustainable energy systems.
            </li>
            <li className="has-before">
            Decentralized energy solutions, like microgrids and rooftop solar, will gain traction, empowering communities and fostering energy independence.
            </li>
            <li className="has-before">
            Cybersecurity threats to critical infrastructure will require robust protection measures to ensure energy security.
            </li>
          </ul>

          <p className="section-text">
          We Advise On :<br></br></p>
          <ul className="about-list">
            <li className="has-before">
            Get funding for renewable energy projects, grid modernization initiatives, and innovative technologies.
            </li>
            <li className="has-before">
            Explore strategic partnerships and mergers for market consolidation, technology acquisition, and diversification.
            </li>
            <li className="has-before">
            Develop robust financial models and investment strategies for navigating regulatory changes and capitalizing on emerging opportunities.
            </li>
            
          </ul>
         
         
        </div>
      </div>
    </section>
  </>
  )
}

export default EnergySectorContent