import {  Link } from "react-router-dom";
import React, { useEffect,useState } from 'react';
import '../styles.css';
import 'remixicon/fonts/remixicon.css';
import logo from '../images/fincept_logo.png';


const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };
    
      const closeMenu = () => {
        setIsMenuOpen(false);
      };
    
    useEffect(() => {
        const handleResize = () => {
            const mediaQuery = window.matchMedia('(min-width: 1118px)');
            const dropdownContainer = document.querySelectorAll('.dropdown__container');

            if (mediaQuery.matches) {
                setIsMenuOpen(false);
                dropdownContainer.forEach((e) => {
                    e.removeAttribute('style');
                });

                document.querySelectorAll('.dropdown__item').forEach((e) => {
                    e.classList.remove('show-dropdown');
                });
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const showMenu = (toggleId, navId) => {
            const toggle = document.getElementById(toggleId);
            const nav = document.getElementById(navId);

            const toggleClickHandler = () => {
                // Add show-menu class to nav menu
                nav.classList.toggle('show-menu');
                // Add show-icon to show and hide menu icon
                toggle.classList.toggle('show-icon');
            };

            toggle.addEventListener('click', toggleClickHandler);

            return () => {
                toggle.removeEventListener('click', toggleClickHandler);
            };
        };

        return showMenu('nav-toggle', 'nav-menu');
    }, []);
    

  return (
    <>
  <header className="header">
            <nav className="nav container">
                <div className="nav__data">
                    <Link to="/" className="nav__logo">
                        <img className="logocss" src={logo} alt="Fincept Pro" />
                    </Link>
    
                    <div className={`nav__toggle ${isMenuOpen ? 'show-menu' : ''}`}  id="nav-toggle"onClick={toggleMenu}>
                        <i className="ri-menu-line nav__toggle-menu"></i>
                        <i className="ri-close-line nav__toggle-close"></i>
                    </div>
                </div>

                {/* <!--=============== NAV MENU ===============--> */}
                <div className={`nav__menu ${isMenuOpen ? 'show-menu' : ''}`} id="nav-menu"onClick={closeMenu}>
                    <ul className="nav__list">
                        



                         <li>
                            <Link to="/about" className="nav__link nav__linkpadding">About Us</Link>
                        </li>
                        {/* <!--=============== DROPDOWN 1 ===============--> */}
                        <li className="dropdown__item">                      
                       <div className="nav__link dropdown__button ">
                       <Link to="/services" >  Services</Link><i className="ri-arrow-down-s-line dropdown__arrow "></i>
                            </div>

                            <div className="dropdown__container">
                                <div className="dropdown__content">
                                    <div className="dropdown__group">
                                        <div className="dropdown__icon">
                                        <i class="ri-bank-fill"></i>
                                        </div>
    
                                        <Link to='/services/investmentbanking'> <span className="dropdown__title">Investment Banking</span></Link>
    
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link to="/services/investmentbanking" className="dropdown__link">Leading specialized investment banking<br></br>  service provider, offering comprehensive <br></br>debt, equity, M&A, and alternative <br></br> investment solutions.</Link>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <div className="dropdown__icon">
                                        <i class="ri-vip-diamond-fill"></i>
                                        </div>
    
                                        <Link to='/services/privatecapital'> <span className="dropdown__title">Private Capital Solution</span></Link>
    
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link to="/services/privatecapital" className="dropdown__link">
                                                Fincept Pro delivers customized private <br></br>capital solutions, drawing on extensive <br></br> industry knowledge and a decade of<br></br> experience.</Link>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <div className="dropdown__icon">
                                        <i class="ri-shake-hands-fill"></i>
                                        </div>
    
                                        <Link to='/services/managementconsultancy'> <span className="dropdown__title">Management Consultancy</span></Link>
    
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link to="/services/managementconsultancy" className="dropdown__link">Tailored market driven consultancy <br></br>solutions, backed by industry <br></br> expertise and data-driven insights, for<br></br> sustainable growth and enhanced <br></br> profitability.</Link>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <div className="dropdown__icon">
                                        <i class="ri-wallet-3-fill"></i>
                                        </div>
    
                                        <Link to='/services/projectfinancing'> <span className="dropdown__title">Project Financing</span></Link>
    
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link to="/services/projectfinancing" className="dropdown__link">Customized project finance solutions <br></br>for significant endeavors, covering<br></br> the entire project lifecycle <br></br>with expertise in debt syndication<br></br> and equity funding.</Link>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <div className="dropdown__icon">
                                        <i class="ri-building-3-fill"></i>
                                        </div>
    
                                       <Link to='/services/privatequity'> <span className="dropdown__title">Private Equity</span></Link>
    
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link to="/services/privatequity" className="dropdown__link">Fincept Pro excels in private<br></br> equity, guiding clients through <br></br>investor sourcing, due diligence,<br></br> and post-investment support for <br></br>optimal outcomes.</Link>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <div className="dropdown__icon">
                                        <i class="ri-git-repository-commits-fill"></i>
                                        </div>
    
                                       <Link to='/services/enterprisevaluation'> <span className="dropdown__title">Enterprise Valuation</span></Link>
    
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link to="/services/enterprisevaluation" className="dropdown__link">Expert enterprise valuation services<br></br> ensure strategic clarity and confidence<br></br> for all transactional endeavors,<br></br> delivering precision and reliability <br></br>at every step.</Link>
                                            </li>
                                           
                                        </ul>
                                    </div>
   
                                </div>
                            </div>
                        </li>

                   
                     
                        <li>
                            <Link to="/sectors" className="nav__link nav__linkpadding">Sectors</Link>
                        </li>
                        <li>
                            <Link to="/blogs" className="nav__link nav__linkpadding">Blogs</Link>
                        </li>
                        <li>
                            <Link to="/contact" className="nav__link nav__linkpadding">Contact Us</Link>
                        </li>

                    </ul>
                </div>
            </nav>
        </header>
   
   
   
    </>
  )
};

export default Navbar;