import React from 'react';
import aboutimg from '../images/AboutBanner.png';
import '../pagestyle.css';
import LazyLoad from 'react-lazyload';

export const AboutBannerPage = () => {
  return  (
    <section className="section" id="home" aria-label="hero">
      
 
        {/* <img src={aboutimg} alt="Sectors banner" className="img-cover"  /> */}
        <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
        <img src={aboutimg} alt="About Us" className="img-cover"  />
          <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
        </LazyLoad>


    </section>
  );
}
// style={{marginTop:'100px',marginRight:'50px'}}
// style={{marginTop:'200px',width:'150px',height:'150px',marginRight:'-20px'}}