import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import industryimg from '../../images/industrial.jpg';
import SectorsCards from '../../components/SectorCards';
import { Helmet } from 'react-helmet';
import IndustrialSectorContent from '../../components/IndustrialSectorContent';

const Industrial = () => {
  return (
    <>
    <Helmet>
   <title>Industrial Sector | Fincept Pro Financial Solutions</title>
    <meta name="description" content="Explore specialized financial solutions for the global industrial sector provided by Fincept Pro."/>
    <meta name="keywords" content="industrial sector financial consulting, industrial finance solutions, industrial investment banking, manufacturing industry financial services"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Industrial Services</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={industryimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
      </LazyLoad>

  </section>
  <IndustrialSectorContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <SectorsCards />
    <Footer/>
    </>
  )
}

export default Industrial