import React from 'react'

const ProjectFinancingContent = () => {
  return (
    <>
    <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
       <div className="container">
       
         <div className="about-content">
 
           <p className=" hero-text ">
           Fincept Pro leads unique project finance solutions, meeting the special needs of large projects and asset acquisition. Our comprehensive services cover the entire project lifecycle, from pre-construction planning to post-construction activities such as financing, operating cash flow, and negotiating loan terms. Our project financing skills include debt consolidation, raising development capital and private equity, and establishing project-specific escrow accounts for efficient payroll processing.  <br></br><br></br> </p>
         
           <p className=" hero-text ">
           Engaging Fincept Pro for project finance ensures a tailored return on your business success; using our niche expertise in cash flow modeling, risk analysis, cost negotiation, and special purpose vehicle planning. With active participation in construction monitoring, credit relationships with leading financial institutions, a strong vendor ecosystem, government policy experts, and deep industry expertise, Fincept Pro delivers more than capital—we offer comprehensive strategic plans tailored to the specific needs and risks of your business.<br></br><br></br> </p>
         
           <p className=" hero-text ">
           From renewable energy to transportation, healthcare, and infrastructure, our experienced teams ensure that your business receives not only financial backing but a strategic vision job as well, increasing investment opportunities and with honed knowledge of the project’s financial challenges. Partnering with Fincept Pro means having a trusted advisor for your business’s financial journey, unlocking the full potential of your business and development efforts, and ensuring successful completion.<br></br><br></br>
           </p>
           <p className="section-text  ">
           Our project financing expertise includes:<br></br></p>
           
           <ul className="about-list"style={{top:'4px'}}>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Debt Syndication:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We leverage relationships with leading Public Sector banks and private debt funds to syndicate optimal debt packages to fund project timelines spanning construction completion.</span>
            <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Equity/Mezzanine Financing:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We advise on securing growth capital/private equity and flexible mezzanine instruments combining features of both debt & equity to bridge overall project financing needs.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Escrow Account Management:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We assist with establishing project-specific escrow accounts to seamlessly manage equity and debt payments tied to construction milestones.</span>
             <br></br>
            
           </ul>
 
           
          
         </div>
       </div>
     </section>
   </>
  )
}

export default ProjectFinancingContent