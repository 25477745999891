import React from 'react'
import { Link } from 'react-router-dom';
import sustainableimg from "../images/Sustainable.jpeg";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { BsArrowUpCircleFill } from "react-icons/bs";


const BlogPageCards = () => {
  return (
   <>
    <section className="section service" id="services" aria-label="service">
      
      <div className="container" style={{textAlign:'center',marginTop:'-60px'}}>
      <BsArrowUpCircleFill style={{ fontSize:'3rem', marginBottom:'30px', color:'#75B8B9'}}/>

        <ul className="grid-list gridlist">
        
          <li>
            <div className="service-card has-after">
            <Link to="/blogs/sustainable-finance"> <figure className="card-icon">
                <img src={sustainableimg} width="100%" height="100%" loading="lazy" alt="UI/UX Creative Design" className="img sectorimg" />
              </figure>
              <div className="card-content">
               <h3 className="h3 card-title"style={{paddingTop:'10px'}}>Sustainable Finance and Technology</h3>
               <p className="card-text">
               Sustainable finance integrates environmental, social, and governance (ESG) considerations into company choices and investment strategies. </p>
                <Link to='/blogs/sustainable-finance' className="btn-link" style={{textAlign:'right'}}>
                  <span className="span">Know More</span>
                  <IoArrowForwardCircleOutline  style={{fontSize:'20px'}}/>
                </Link>
            
                
              </div></Link>
            </div>
          </li>
         

         
          {/* Add more <li> items for additional services */}
        </ul>
      </div>
    </section>
   </>
  )
}

export default BlogPageCards