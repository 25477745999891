import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import managementimg from '../../images/management.jpg';
import Servicescards from '../../components/Servicescards';
import ManagementConsultancyContent from '../../components/page content/ManagementConsultancyContent';
import { Helmet } from 'react-helmet';

const ManagementConsultancy = () => {
  return (
    <>
    <Helmet>
    <title>Management Consultancy | Fincept Pro</title>
    <meta name="description" content="Fincept Pro is your partner in unlocking your economic potential, providing tailored financial management solutions backed by extensive industry expertise. Our services include cost optimization, working capital management, financial modeling, risk management, and mergers and acquisitions advisory."/>
    <meta name="keywords" content="Fincept Pro, financial management, cost optimization, working capital management, financial modeling, risk management, mergers and acquisitions advisory, management consultancy, industry insights, strategic decision-making, financial flexibility, liquidity management, compliance, value creation"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Management Consultancy</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={managementimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
      </LazyLoad>

  </section>
  <ManagementConsultancyContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <Servicescards />
    <Footer/>
    </>
  )
}

export default ManagementConsultancy