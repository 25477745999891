import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import privateimg from '../../images/privateequity.jpg';
import Servicescards from '../../components/Servicescards';
import PrivateEquityContent from '../../components/page content/PrivateEquityContent';
import { Helmet } from 'react-helmet';

const PrivateEquity = () => {
  return (
    <>
    <Helmet>
   <title>Private Equity | Fincept Pro - Specialized Services</title>
    <meta name="description" content="Fincept Pro offers specialized private equity services to enhance your financial journey, from investor sourcing to post-investment support."/>
    <meta name="keywords" content="Fincept Pro, private equity, investor sourcing, valuation advisory, due diligence, term sheet finalization, post-investment support"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Private Equity</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={privateimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
      </LazyLoad>

  </section>
  <PrivateEquityContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <Servicescards />
    <Footer/>
    </>
  )
}

export default PrivateEquity