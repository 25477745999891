import React from 'react'

const PrivateCapitalSolutionContent = () => {
  return (
    <>
    <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
       <div className="container">
       
         <div className="about-content">
 
           <p className=" hero-text ">
           FinceptPro positions itself as a leading provider of private captial solutions, catering to the diverse financial needs of businesses across industries. Our comprehensive range of services makes it easy to raise capital for any aspect of your business, whether it’s growth, expansion, or working capital needs.  <br></br><br></br> </p>
         
           <p className=" hero-text ">
           Our private equity solutions include a range of product offerings, including creating minority share equity investments for growth capital, structured loan solutions for optimal flexibility, accessing revenue-based financing tied to growth metrics, and financing M&A programs through comprehensive funding packages. By enlisting the services of Fincept Pro for private capital solutions, you can expect a customized and efficient approach that is specifically crafted to suit your individual needs. We adamantly oppose a one-size-fits-all strategy and instead provide a range of tailored approaches to fulfill your precise requirements. With our expert
            knowledge, you can rest assured that you will receive top-tier service and meticulous attention to detail. <br></br><br></br> </p>

           <p  className=" hero-text ">
           With over 10 years of experience in private capital solutions, our deep industry expertise streamlines financial planning and uses negotiations to further your interests. Leveraging our proprietary investor network, we access attractive investment opportunities and valuable partnerships. Our commitment to results, collaborative approach, and comprehensive industry expertise make Fincept Pro the right partner to navigate the complexity of private capital solutions and unlock your business development power edge.<br></br><br></br> 
           </p>
         
           <p className="section-text  ">
           Our private capital solutions suite includes: <br></br></p>
           
           <ul className="about-list"style={{top:'4px'}}>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Growth Capital Financing:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We help clients secure minority stake investments from private equity firms, family offices, and high-net-worth individuals to fund growth plans. </span>
            <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Structured Debt Solutions:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We design senior debt, mezzanine, and untrenched structures, combining features of debt and equity to ensure maximum flexibility at optimal costs.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Revenue-Based Financing:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Our partnerships with leading revenue-based financing providers allow clients to access risk-adjusted credit lines and working capital tied to their revenue streams and growth metrics.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Acquisition Financing:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>We work closely with clients to finance their merger, acquisition, and consolidation plans through comprehensive funding packages involving equity, debt, and contingent payouts.</span>
            
           </ul>
 
           
          
         </div>
       </div>
     </section>
   </>
  )
}

export default PrivateCapitalSolutionContent