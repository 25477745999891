import React from 'react'




const InvestmentBankingContent = () => {
  return (
  <>
   <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
      <div className="container">
      
        <div className="about-content">

          <p className=" hero-text ">
          FinceptPro stands as one of the leading specialised banking service provider, offering a comprehensive suite of debt and equity solutions, mergers and acquisitions (M&A), and alternative investments. Our mission is to connect ideal clients with alternative capital raising strategies and a team of experienced M&A specialists. With 11 years of experience, we have successfully executed M&A transactions, private equity placements, IPOs, and debt financing in a variety of industries. <br></br><br></br> </p>
        
          <p className=" hero-text ">
          Our end-to-end strategy, backed by sound financial analysis, ensures clients get the most out of investments, fundraising, M&A, and restructuring. Our customized solutions can provide significant advantages for varied sectoral businesses, which are uniquely designed based on company needs, investor expectations, size, and industry dynamics, and deliver consistently superior results. Advanced in technology, we use the latest virtual data buildings, APIA, analytics tools, and platforms to reduce errors and 
          enable easy transactions, which is especially useful for foreign investors. Our investment banking team, comprised of experienced industry professionals, goes beyond traditional communication approaches to gain a comprehensive understanding of our clients' needs. This enables us to develop tailored solutions that deliver maximum value. <br></br><br></br> </p>
        
          <p className="section-text  ">
          Our services include: <br></br></p>
          
          <ul className="about-list"style={{top:'4px'}}>
            <li className="has-before dots" style={{ display:'inline'}}>
            <h3  style={{display:'inline', marginRight:'10px' }}>Equity Capital Raising:</h3> 
            </li>
            <span className='section-text'style={{ display:'inline' }}>We help clients raise growth capital from private equity firms, hedge funds, pension funds, and sovereign wealth funds. This includes private placements, Pre-Initial public offerings financing, secondary offerings for listed firms, and block trades.</span>
           <br></br>
            <li className="has-before dots" style={{ display:'inline'}}>
            <h3  style={{display:'inline', marginRight:'10px' }}>Debt Capital Raising:</h3> 
            </li>
            <span className='section-text'style={{ display:'inline' }}>We advise on securing optimal debt financing, be it senior debt, mezzanine, or flexible loan instruments from banks, credit funds, and other lenders to fund growth needs in a non-dilutive manner.</span>
            <br></br>
            <li className="has-before dots" style={{ display:'inline'}}>
            <h3  style={{display:'inline', marginRight:'10px' }}>Mergers & Acquisitions:</h3> 
            </li>
            <span className='section-text'style={{ display:'inline' }}>We provide buy-side and sell-side M&A advisory, including identifying targets, deal negotiation, due diligence, valuation, transaction structuring, regulatory approvals, and post-merger integration activities in both domestic and cross-border deals.</span>
            <br></br>
            <li className="has-before dots" style={{ display:'inline'}}>
            <h3  style={{display:'inline', marginRight:'10px' }}>Financial Restructuring:</h3> 
            </li>
            <span className='section-text'style={{ display:'inline' }}>We advise distressed companies, creditors, and investors on balancing debt-equity structures, including debt refinancing, recapitalization, quasi-equity structures, loan renegotiations, and capital optimization to steer companies from financial trouble.</span>
           
          </ul>

          
         
        </div>
      </div>
    </section>
  </>
  )
}

export default InvestmentBankingContent