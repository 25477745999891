import React from 'react'

const ManagementConsultancyContent = () => {
  return (
    <>
    <section className="section " id="about"  aria-label="about" style={{marginTop:'-70px'}} >
       <div className="container">
       
         <div className="about-content">
 
           <p className=" hero-text ">
           Fincept Pro is your partner in unlocking your economic potential, providing solutions that are tailored and backed by extensive industry expertise. We are here to guide companies and businesses through economic challenges for sustainable growth. Our all-inclusive financial management services include cost optimization, working capital management, financial modelling and forecasting, risk management, and mergers and acquisitions (M&A) advisory, catering to a variety of corporate needs as well as for anyone working on solving unique business challenges.  <br></br><br></br> </p>
         
           <p className=" hero-text ">
           With data-driven insights, our impressive track record shows excellent client engagement across multiple sectors, consistently exceeding expectations. In addition to management consultancy services, we examine the unique challenges of each industry, providing data-backed insights through advanced research and industry best practices. <br></br><br></br> </p>

           <p  className=" hero-text ">
           We establish strong partnerships by understanding specific needs and objectives to develop integrated solutions seamlessly across all processes. With a team having expertise in a variety of industries, rigorous monitoring of trends, and assistance with customized solutions to unique challenges set us apart. Boasting an extensive industry network, we provide valuable insights, resources and networks that accelerate growth.<br></br><br></br> 
           </p>
         
           <p className="section-text  ">
           Our Finance management services include:<br></br></p>
           
           <ul className="about-list"style={{top:'4px'}}>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Cost Optimization:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Streamlining operations and identifying cost-saving opportunities, ensuring resources are maximized and profitability is enhanced. </span>
            <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Working Capital Management:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Optimizing cash flow and minimizing working capital gaps, unlocking liquidity, and improving financial flexibility.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Financial Modeling and Forecasting:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Building accurate financial models that inform strategic decision-making, supporting informed investments and growth initiatives.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Risk Management:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Implementing robust strategies to mitigate financial risks and ensure compliance with industry regulations, safeguarding your business from potential threats.</span>
             <br></br>
             <li className="has-before dots" style={{ display:'inline'}}>
             <h3  style={{display:'inline', marginRight:'10px' }}>Mergers & Acquisitions (M&A) Advisory:</h3> 
             </li>
             <span className='section-text'style={{ display:'inline' }}>Guiding clients through the complexities of M&A transactions, from initial planning to successful integration, maximizing value creation.</span>
           
           </ul>
 
           <p >Our comprehensive suite of services addresses a wide range of corporate needs tailored to each client's unique industry challenges.<br></br><br></br></p>
          
         </div>
       </div>
     </section>
   </>
  )
}

export default ManagementConsultancyContent