import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import consumerimg from '../../images/consumer.jpg';
import SectorsCards from '../../components/SectorCards';
import { Helmet } from 'react-helmet';
import ConsumerSectorContent from '../../components/ConsumerSectorContent';

const Consumer = () => {
  return (
    <>
    <Helmet>
    <meta charset="UTF-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>Consumer and Retail Sector | Fincept Pro Finance Solutions</title>
    <meta name="description" content="Explore financial solutions tailored for the consumer and retail industry provided by Fincept Pro."/>
    <meta name="keywords" content="consumer finance services, retail sector financial consulting, consumer goods investment banking, retail industry finance solutions"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Consumer and retail Services</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={consumerimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
        
      </LazyLoad>

  </section>
  <ConsumerSectorContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <SectorsCards />
    <Footer/>
    </>
  )
}

export default Consumer