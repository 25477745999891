import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import projectimg from '../../images/projectfinance.jpg';
import Servicescards from '../../components/Servicescards';
import ProjectFinancingContent from '../../components/page content/ProjectFinancingContent';
import { Helmet } from 'react-helmet';

const ProjectFinancing = () => {
  return (
    <>
    <Helmet>
    <title>Project Financing | FinceptPro - Unique Solutions</title>
    <meta name="description" content="FinceptPro offers unique project finance solutions tailored to large projects and asset acquisition, covering the entire project lifecycle."/>
    <meta name="keywords" content="FinceptPro, project finance, debt syndication, equity financing, mezzanine financing, escrow account management, financial planning"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Project Financing</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={projectimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
      </LazyLoad>

  </section>
  <ProjectFinancingContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <Servicescards />
    <Footer/>
    </>
  )
}

export default ProjectFinancing