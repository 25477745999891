import React from 'react'
import Footer from '../../components/Footer';
import LazyLoad from 'react-lazyload';
import privatecapimg from '../../images/PrivateCapital.jpg';
import Servicescards from '../../components/Servicescards';
import PrivateCapitalSolutionContent from '../../components/page content/PrivateCapitalSolutionContent';
import { Helmet } from 'react-helmet';

const PrivateCapital = () => {
  return (
    <>
    <Helmet>
    <title>Private Capital Solutions | Fincept Pro </title>
    <meta name="description" content="FinceptPro offers private capital solutions tailored to businesses' financial needs, including growth capital, structured debt, revenue-based financing, and acquisition funding."/>
    <meta name="keywords" content="FinceptPro, private capital solutions, growth capital financing, structured debt solutions, revenue-based financing, acquisition financing, private equity, minority stake investments"/>

    </Helmet>
    <section className="section" id="home" aria-label="hero">
      
    <h1 style={{position:'absolute',marginTop:'-600px',fontSize:'1px'}}>Private Capital</h1> 
      <LazyLoad height={200} offset={100} placeholder={<div>Loading...</div>}>
      <img src={privatecapimg} alt="About Us" className="img-cover"  />
      <div className="circle two circlesizeone"></div>
          <div className="circle two circlesizetwo"></div>
      </LazyLoad>

  </section>
  <PrivateCapitalSolutionContent/>
  <hr style={{backgroundColor:'#e9e9e9',height:'2px',border:'none',color:'#e9e9e9'}}></hr>
  <Servicescards />
    <Footer/>
    </>
  )
}

export default PrivateCapital